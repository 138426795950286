<template>
  <div class="">
    <Loading :isLoading="isLoading" />
    <div>
      <p style="padding:10px 10px 0px 10px;font-size:15px;color:#810042;font-weight:700;font-family:Lato;">{{ (selecURLByName[0].text != undefined)? selecURLByName[0].text.toUpperCase(): '' }}</p>
    </div>
    <div class="py-2 md:flex md:flex-wrap gap-10">
      <div class="w-full md:w-3/4 outline-none shadow-md border-2 rounded-lg py-2 focus:border-blue">
        <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true" />
      </div>
      <div class="w-full mt-10 md:mt-0 md:w-1/5">
          <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 pb-10" style="border-color:#FFC300;">
            <label class="px-3 py-5 text-lg font-medium	" style="color:#810042;">Historial de Referidos <br> ( {{selecURLByName[0].text}} )</label>
              <div class="w-full px-5 py-2 border-2 rounded-lg flex shadow-lg mt-5" style="border-color:#FFC300;" v-for="(data,index) of selecURLByName" :key="index" >
               <div class="w-4/5 pt-5" style="color:#810042;">
                <p class="text-4xl" style="color:#810042;">{{data.title}}</p>
                <p class="" style="color:#810042;">{{data.text.toUpperCase()}}</p>
               </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tailwind.css';
import Referral from "@/classes/Referral.js";
import Loading from '@/components/Loading/VueLoading.vue';
//Iconos
import checkIcon from '@/assets/yes.svg'
import crossIcon from '@/assets/no.svg'

import TableComponent from "@/components/Forms/Tables/TableComponent";
import { onMounted, ref, getCurrentInstance } from 'vue-demi'
import Swal from 'sweetalert2';
import {useRouter} from "vue-router";
export default {
    name:"ReferralHistorySelect",
    components:{
      Loading,
      TableComponent
    },
    setup(){
      const router = useRouter();
      let isLoading=ref(false);
      let referral=new Referral();
      let app = getCurrentInstance();

      let referralHistoryList = ref([]);
      let columns = ref([]);
      let rows = ref([]);
      let fields = ref([]);
      let render = ref(false);
      let referenceName = ref("");
      let referenceId = ref("");
      let selecURLByName=ref([{url:"",title:"",text:"",nameUrl:""}]);

      let elementList=ref([
        {url:"/referals/get_not_sent_",title:"",text:"Invitaciones no enviadas",show:true,nameUrl:"solicitudes-no-enviadas"},//no enviado
        {url:"/referals/get_sent",title:"",text:"Invitaciones enviadas",show:true,nameUrl:"solicitudes-enviadas"},//enviado
        {url:"/referals/get_open_link",title:"",text:"Solicitudes abiertas",show:true,nameUrl:"solicitudes-abiertas"},//abiertas
        {url:"/referals/get_account_created",title:"",text:"Cuentas creadas",show:true,nameUrl:"solicitudes-cuentas-creadas"},
        {url:"/referals/get_incomplete_request",title:"",text:"Solicitud incompleta",show:true,nameUrl:"solicitud-incompleta"},
        {url:"/referals/get_complete_request",title:"",text:"Solicitud completa",show:true,nameUrl:"solicitud-completa"},
        {url:"/referals/get_accepted_request",title:"",text:"Solicitud aceptada",show:true,nameUrl:"solicitud-aceptada"},
        {url:"/referals/get_rejected_request",title:"",text:"Solicitud rechazada",show:true,nameUrl:"solicitud-rechazada"},
      ]);

      let elementAssistedList=ref([
        {url:"",title:"0",text:"Total",show:true,nameUrl:"solicitudes-asistida"},
        {url:"",title:"0",text:"Solicitudes Capturadas",show:true,nameUrl:"solicitudes-asistida-capturadas"},
        {url:"",title:"0",text:"Solicitudes En Revisión",show:true,nameUrl:"solicitud-asistida-en-revision"},
        {url:"",title:"0",text:"Solicitudes Aceptadas",show:true,nameUrl:"solicitud-asistida-aceptadas"},
        {url:"",title:"0",text:"Solicitudes Rechazadas",show:true,nameUrl:"solicitud-asistida-rechazadas"},
      ]);

      onMounted(async()=>{
        isLoading.value = true;
        referenceName.value = router.currentRoute._value.params.name;
        let data = await elementList.value.filter(e=> e.nameUrl==referenceName.value);
        app = app.appContext.config.globalProperties;

        if(data.length === 0){
          referenceId.value = router.currentRoute._value.params.id;
          const category = router.currentRoute._value.params.category;
          selecURLByName.value = await elementAssistedList.value.filter((e, index)=> { 
            if( e.nameUrl==referenceName.value){
              e.position = index; 
              return e;
            }
          });
          await getListOfReferalsAssisted(referenceId.value, category, selecURLByName.value.position);
          return;
        }

        selecURLByName.value = data;
        await quantityRegisters();
        getColumns();
        getRows();
        render.value = true;
        isLoading.value=false;
      });

      async function getListOfReferalsAssisted(user_id, category, index){
        console.log("getListOfReferalsAssisted:",user_id, category);
        var data = [];

        let referralList = await referral.get({
          "token_auth":sessionStorage.getItem("login")
        },"referals/list_of_referals_assisted").then(resp => resp.data.response.referals).catch(err => console.log("Error:", err));
        
        if(referralList.length==0){
          return;
        }

        referralList = referralList.filter( referral => {
          if(user_id != 0){
            if(referral.fonsnte_user_id == user_id){
              return referral;
            }
          }else{
            return referral;
          }
        });

        console.log("referralList:", referralList);
       
        referralList.forEach( referral => {
          if(category != 'todas'){
            if(referral.admission_request.status === category){
              data.push({
                name: referral.name,
                cellphone: app.$filters.formatPhone(referral.cellphone),
                email: referral.email,
                invitation_date: referral.admission_request!=null?referral.admission_request.is_assisted_request==true? app.$filters.formatDate(referral.admission_request.created_at):"N/A":"N/A",
                status: app.$filters.requestStatus(referral.admission_request.status),
                registration_date: app.$filters.formatDate(referral.created_at),
                referred_by: referral.fonsnte_user!=null?`${referral.fonsnte_user.person[0].name} ${referral.fonsnte_user.person[0].middle_name} ${referral.fonsnte_user.person[0].maternal_name} ${referral.fonsnte_user.person[0].paternal_name}`:"",
                email_sent: referral.total_sent==1?"Si":"No",
                referenced_user_id: referral.referenced_user_id
              });
            }
          }else if(category == 'todas'){
            data.push({
              name: referral.name,
              cellphone: app.$filters.formatPhone(referral.cellphone),
              email: referral.email,
              invitation_date: referral.admission_request!=null?referral.admission_request.is_assisted_request==true? app.$filters.formatDate(referral.admission_request.created_at):"N/A":"N/A",
              status: app.$filters.requestStatus(referral.admission_request.status),
              registration_date: app.$filters.formatDate(referral.created_at),
              referred_by: referral.fonsnte_user!=null?`${referral.fonsnte_user.person[0].name} ${referral.fonsnte_user.person[0].middle_name} ${referral.fonsnte_user.person[0].maternal_name} ${referral.fonsnte_user.person[0].paternal_name}`:"",
              email_sent: referral.total_sent==1?"Si":"No",
              referenced_user_id: referral.referenced_user_id
            });
          }
        });

        console.log("referralList:", referralList);

        columns.value = [
          { headerName: "Nombre", field: 'name', headerCheckboxSelection: true, checkboxSelection: true},
          { headerName: "Teléfono", field: 'cellphone' },
          { headerName: "Correo", field: 'email' },
          { headerName: "Fecha de invitación", field: 'invitation_date', filter: true},
          { headerName: "Estatus", field: 'status' },
          { headerName: "Fecha de inscripción", field: 'registration_date', filter: true, },
          { headerName: "Referido por:", field:"referred_by", filter: true },
          { headerName: "Correo Enviado", field:"email_sent", cellRenderer: params => isCheckIcon(params) },
        ];
        
        selecURLByName.value[0].title = data.length;
        rows.value = data;
        render.value = true;
        isLoading.value = false;
      }

      async function quantityRegisters(){
        isLoading.value=true;
        try {
          const _response=await referral.get({
            "token_auth":sessionStorage.getItem("login"),
            "id":null
          },`${selecURLByName.value[0].url}`);
          isLoading.value=false;
           if(_response.data.response.has_errors){
              let messages="";
              _response.data.errors.forEach(element => {
                messages+="<strong>■ "+element+".</strong><br>"
              });
              Swal.fire({
                title: "Aviso",
                html:_response.data.response.message+"<br>"+messages,
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
              });
              }else{
                let res=_response.data.response.data;
                selecURLByName.value[0].title=res.length;
                if(selecURLByName.value[0].url=="foncabsa_referals/get_not_sent" || selecURLByName.value[0].url=="foncabsa_referals/get_sent" || selecURLByName.value[0].url== "foncabsa_referals/get_complete_request" || selecURLByName.value[0].url=="foncabsa_referals/get_complete_request"){
                  res.forEach(element=>{
                     referralHistoryList.value.push(new Object({
                        name:element.name,
                        cellphone:element.cellphone,
                        email:element.email,
                        invitation_date:element.admission_request!=null?element.admission_request.is_assisted_request==true? element.admission_request.created_at:"N/A":"N/A",
                        status:element.status,
                        registration_date:element.created_at,
                        referred_by:element.fonsnte_user!=null?`${element.fonsnte_user.person[0].name} ${element.fonsnte_user.person[0].middle_name} ${element.fonsnte_user.person[0].maternal_name} ${element.fonsnte_user.person[0].paternal_name}`:"",
                        email_sent:element.total_sent
                      })
                    )
                  });
                }
                else{
                  res.forEach(element=>{
                     referralHistoryList.value.push(new Object({
                        name:element.name,
                        cellphone:element.cellphone,
                        email:element.email,
                        invitation_date:element.admission_request!=null?element.admission_request.is_assisted_request==true? element.admission_request.created_at:"N/A":"N/A",
                        status:element.status,
                        registration_date:element.created_at,
                        referred_by:element.fonsnte_user!=null?`${element.fonsnte_user.person[0].name} ${element.fonsnte_user.person[0].middle_name} ${element.fonsnte_user.person[0].maternal_name} ${element.fonsnte_user.person[0].paternal_name}`:"",
                        email_sent:element.total_sent,
                        autorizo: existDocuments(element,'autorizo'),
                        comprobante_de_domicilio:existDocuments(element,'comprobante_de_domicilio'),
                        csf: existDocuments(element,'csf'),
                        curp: existDocuments(element,'curp'),
                        identificacion: existDocuments(element,'identificacion'),
                        ine: existDocuments(element,'ine'),
                        parte_social:existDocuments(element,'parte_social'),
                        solicitud: existDocuments(element,'solicitud'),
                        ultimo_estado_de_cuenta: existDocuments(element,'ultimo_estado_de_cuenta'),
                      })
                    )
                  });
                }
               isLoading.value=false;
              }
          }catch (error) {
            isLoading.value=false;
            console.log(error)
            Swal.fire({
              icon:"error",
              title:"Error",
              text:"Ocurrio un error inesperado."
            });

          }
      }

      function existDocuments(element,node){
        try{
          return element.admission_request.registred_documents[node]==true?"Sí":"No"
        }
        catch{
          return "N/A"
        }
      }

    const getColumns = () => {
      if(referralHistoryList.value.length == 0){
        return;
      }
      if(selecURLByName.value[0].url=="foncabsa_referals/get_not_sent" || selecURLByName.value[0].url=="foncabsa_referals/get_sent" || selecURLByName.value[0].url== "foncabsa_referals/get_complete_request" || selecURLByName.value[0].url=="foncabsa_referals/get_complete_request"){
        columns.value = [
          { headerName: "Nombre", field: 'name' },
          { headerName: "Teléfono", field: 'cellphone' },
          { headerName: "Correo", field: 'email' },
          { headerName: "Fecha de invitación", field: 'invitation_date' },
          { headerName: "Status", field: 'status' },
          { headerName: "Fecha de inscripción", field: 'registration_date' },
          { headerName: "Referido por:", field:"referred_by" },
          { headerName: "Correo Enviado", field:"email_sent" }
        ];
      }else{
        columns.value = [
          { headerName: "Nombre", field: 'name' },
          { headerName: "Teléfono", field: 'cellphone' },
          { headerName: "Correo", field: 'email' },
          { headerName: "Fecha de invitación", field: 'invitation_date' },
          { headerName: "Status", field: 'status' },
          { headerName: "Fecha de inscripción", field: 'registration_date' },
          { headerName: "Referido por:", field:"referred_by" },
          { headerName: "Correo Enviado", field:"email_sent" },
          { headerName: "Autorizo", field:"autorizo", cellRenderer: params => isCheckIcon(params) },
          { headerName: "Comproban De Domicilio", field:"comprobante_de_domicilio", cellRenderer: params => isCheckIcon(params) },
          { headerName: "CSF", field:"csf", cellRenderer: params => isCheckIcon(params) },
          { headerName: "Curp", field:"curp", cellRenderer: params => isCheckIcon(params) },
          { headerName: "CURP", field:"identificacion", cellRenderer: params => isCheckIcon(params) },
          { headerName: "INE", field:"ine", cellRenderer: params => isCheckIcon(params) },
          { headerName: "Pago Social", field:"parte_social", cellRenderer: params => isCheckIcon(params) },
          { headerName: "Solicitud", field:"solicitud", cellRenderer: params => isCheckIcon(params) },
          { headerName: "Último Estado De Cuenta", field:"ultimo_estado_de_cuenta", cellRenderer: params => isCheckIcon(params) },
        ];
      }       
      columns.value.forEach(element => {
        fields.value.push(element.field);
      });
    }

    const getRows = () => {
      if(referralHistoryList.value.length == 0){
        return;
      }
      rows.value = referralHistoryList.value.filter((admission) => {
          for (let i in admission) {
            if(!fields.value.includes(i)){
              delete admission[i];
            }
          }

          return formatFields(admission);
      });
    }

    const isCheckIcon = (params) => {
      if(selecURLByName.value[0].url=="foncabsa_referals/get_not_sent" || selecURLByName.value[0].url=="foncabsa_referals/get_sent" || selecURLByName.value[0].url== "foncabsa_referals/get_complete_request" || selecURLByName.value[0].url=="foncabsa_referals/get_complete_request"){
        const value = params.value>0 ? `<img class="mx-auto" src=${checkIcon} loading="lazy" />`:`<img class="mx-auto" src=${crossIcon} loading="lazy" />`;
        return value;
      }
      else{
        const value = params.value=="Sí" ? `<img class="mx-auto" src=${checkIcon} loading="lazy" />`:`<img class="mx-auto" src=${crossIcon} loading="lazy" />`;
        return value;
      }
    }

    const formatFields = (fields) => {
      const statusFields = ['proceso'];
      const lowercaseFields = ['email',"status"];
      const dateFields = ['invitation_date',"registration_date"];
      const phoneFields = ['cellphone'];
      const uppercaseFields = ['curp',"name"];

      for(let i in fields){
        if(lowercaseFields.includes(i)){
          fields[i] = fields[i].toString().toLowerCase();
        }else if(uppercaseFields.includes(i)){
          fields[i] = fields[i].toString().toUpperCase();
        }else if(dateFields.includes(i)){
          fields[i] = app.$filters.formatDate(fields[i]);
        }else if(phoneFields.includes(i)){
          fields[i] = app.$filters.formatPhone(fields[i]);
        }else if(statusFields.includes(i)){
          fields[i] = app.$filters.requestStatus(fields[i]);
        }
      }
      return fields;
    }

    return {
      isLoading,
      columns,
      rows,
      render,
      elementList,
      selecURLByName
    }


    }
}
</script>